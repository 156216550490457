<template>
  <div v-if="completionCert" class="card overflow-y mb-1">
    <div class="card-content p-4">
      <div class="is-size-5 has-text-weight-semibold has-text-centered">
        Completion Certificate
      </div>
      <div class="is-size-7 has-text-weight-semibold has-text-centered mb-3">
        Cert Number: {{ completionCert.certNumber }}
      </div>
      <div v-if="completionCert.briefing" class="notification is-size-7 has-text-centered mb-3 p-2 multiline">
        {{ completionCert.briefing }}
      </div>
      <div v-if="qrcodeValue" class="container has-text-centered mt-3 mb-3">
        <qrcode-vue :value="qrcodeValue" :size="150" level="H" />
      </div>
      <div class="has-text-weight-semibold is-size-7">
        Certificate details
      </div>
      <div class="container is-size-7">
        Title: {{ completionCert.adventureName }}
      </div>
      <div class="container is-size-7">
        Started: {{ completionCert.startedAt.toDate().toLocaleString() }}
      </div>
      <div class="container is-size-7">
        Completed: {{ completionCert.completedAt.toDate().toLocaleString() }}
      </div>
      <div class="container is-size-7">
        Points: {{ completionCert.totalPoints }}
      </div>
      <div class="container is-size-7">
        Duration: {{ ((completionCert.completedAt.toDate().getTime() - completionCert.startedAt.toDate().getTime())/1000/60).toFixed(2) }} minutes
      </div>
      <div class="container is-size-7">
        Players ({{ completionCert.teamMembers.length }}): {{ completionCert.teamMembers.map(x => x.name).join(', ') }}
      </div>
      <FormInput
      class="mt-2 mb-0"
      :size="'medium'" :title="'Send to email'" :type="'text'" :value="email" v-on:update="email = $event"
      />
      <div @click="sendEmail()"
      class="button is-primary-colors has-text-weight-semibold is-fullwidth mt-1"
      v-bind:class="{'is-light is-loading': loading}"
      :disabled="!email"
      >
        <i class="fas fa-paper-plane pointer mr-1"></i>
        Send email
      </div>
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'
import QrcodeVue from 'qrcode.vue'
import FormInput from '@/components/forms/FormInput'

export default {
  name: 'CompletionCertificate',
  components: {
    QrcodeVue,
    FormInput
  },
  data () {
    return {
      completionCert: null,
      email: null,
      loading: null
    }
  },
  computed: {
    adventureTeam() {
      return this.$store.state.adventureTeam
    },
    qrcodeValue(){
      if (this.adventureTeam) {
        return `${window.location.origin}/cert?number=${this.adventureTeam.certNumber}`
      }
      return null
    }
  },
  methods: {
    getCompletionCert () {
      firebaseApp.firestore().collection('completionCerts')
      .doc(this.adventureTeam.certNumber)
      .get()
      .then(doc => {
        this.completionCert = doc.data()
      })
    },
    sendEmail () {
      this.loading = true
      const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
      masterFunction({
        methodName: 'send-cert-email',
        certNumber: this.adventureTeam.certNumber,
        email: this.email
      }).then(() => {
        this.email = null
      }).catch(err => {
        this.errorMessage = err
        console.log(err)
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    if (this.adventureTeam && this.adventureTeam.certNumber) {
      this.getCompletionCert()
    }
  },
  watch: {
    adventureTeam(newAdventureTeam, oldAdventureTeam){
      if(!oldAdventureTeam.certNumber && newAdventureTeam.certNumber){
        this.getCompletionCert()
      }
    }
  }
}
</script>

<style>
</style>
