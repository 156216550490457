<template>
  <div class="container" style="padding-bottom: 70px">
    <div class="card challenge-container mb-1">
      <div class="card-content pb-6 px-4 pt-1">
        <CardNavigation/>
        <ViewsStat :pageDetails="{pageName: 'debrief'}"/>
        <div class="columns is-mobile m-0 p-0 is-vcentered">
          <div class="column is-1 has-text-centered pointer">
            <i @click="goToStage()" class="fas is-size-4 fa-arrow-circle-left"></i>
          </div>
          <div class="column">
            <div class="container has-text-centered mb-3">
              <div v-if="adventureTeam && !adventureTeam.debrief" class="is-size-4 has-text-weight-semibold has-baskerville-font has-text-brand-color mt-2">
                {{ this.$store.state.uiMods.game }} completed
              </div>
            </div>
          </div>
          <div class="column is-1 has-text-centered">
          </div>
        </div>
        <div class="mb-3 notranslate" :key="adventureTeam.debrief" v-markdown>{{ adventureTeam.debrief }}</div>
      </div>
    </div>
    <div v-if="!adventureTeam.uiMods.hidePointsStarsWording && (!adventureTeam.hideLeaderboard || adventureTeam.showLeaderboardAtCompletion)" class="card overflow-y mb-1">
      <div class="card-content has-text-centered p-4">
        <div class="is-size-5 has-text-weight-semibold has-text-centered">
          Final score
        </div>
        <div class="container is-size-3 has-text-centered mb-2">
          <b>{{ adventureTeam.points }}</b> {{ $store.state.uiMods.point.toLowerCase() }}s
        </div>
        <div
        @click="$router.push({ name: 'Leaderboard' })"
        class="button is-primary-colors is-small has-text-weight-semibold">
          {{ t('View leaderboard') }}
        </div>
      </div>
    </div>
    <!-- temporarily removed -->
    <!-- <div class="card overflow-y">
      <div class="card-content p-4">
        <div v-if="!adventureTeam.star">
          <div class="container has-text-centered mb-3 has-text-weight-semibold">
            {{ starRemarks }}
          </div>
          <nav class="level is-mobile px-4">
            <div v-for="index in 5" :key="index" @click="updateStar(index)" class="level-item has-text-centered pointer">
              <i v-if="index >= star" class="far fa-2x fa-star"></i>
              <i v-if="index < star" class="fas fa-2x fa-star" style="color: #FED892"></i>
            </div>
          </nav>
          <div class="container">
            <div
            @click="giveStar()"
            class="button is-primary-colors has-text-weight-semibold is-fullwidth"
            v-bind:class="{'is-light is-loading': loading}"
            :disabled="!star || loading"
            >
              Rate game
            </div>
          </div>
        </div>
        <div v-else>
          <div class="container has-text-centered mb-3 has-text-weight-semibold">
            Thank you for rating the game!
          </div>
          <nav class="level is-mobile px-4 mb-2">
            <div v-for="index in 5" :key="index" class="level-item has-text-centered">
              <i v-if="index >= adventureTeam.star" class="far fa-2x fa-star"></i>
              <i v-if="index < adventureTeam.star" class="fas fa-2x fa-star" style="color: #FED892"></i>
            </div>
          </nav>
        </div>
      </div>
    </div> -->
    <completion-certificate />
    <div class="card overflow-y has-rounded-bottom-corners">
      <div class="card-content pt-4">
        <div
        @click="leaveGame()"
        class="button is-primary-colors has-text-weight-semibold is-fullwidth">
          {{ t(`Leave ${$store.state.uiMods.game.toLowerCase()}`) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardNavigation from '@/components/CardNavigation'
import firebaseApp from '@/firebase/init'
import ViewsStat from '@/components/ViewsStat.vue'
import CompletionCertificate from '@/components/CompletionCertificate.vue'

export default {
  name: 'Debrief',
  components: {
    ViewsStat,
    CardNavigation,
    CompletionCertificate
  },
  data() {
    return {
      loading: false,
      starRemarks: 'Click on a star below to rate the game!',
      star: null
    }
  },
  computed: {
    adventureTeam() {
      return this.$store.state.adventureTeam
    },
    currentStage() {
      return this.$store.state.currentStage
    },
    stageDetails() {
      if(this.adventureTeam){
        if(this.adventureTeam.stage === this.currentStage){
          return this.adventureTeam.stageDetails
        } else {
          return this.adventureTeam.stageDetailsHistory[this.currentStage-1]
        }
      } else {
        return null
      }
    }
  },
  watch: {
    adventureTeam(newAdventureTeam, oldAdventureTeam) {
      if (!newAdventureTeam?.completedAt && oldAdventureTeam?.completedAt) {
        this.$router.push({ name: 'Stage' })
      }
    }
  },
  mounted() {
    if(this.adventureTeam && !this.adventureTeam.completedAt){
      this.$router.push({ name: 'Stage' })
    }

    this.$store.dispatch('enterPage', {
      pageName: 'debrief'
    })

    this.$store.commit('updateShowNextStageAlert', false)

    document.querySelector('html').style.backgroundImage = null
    document.querySelector('html').style.backgroundColor = this.$store.state.uiMods.defaultBackgroundColour ? this.$store.state.uiMods.defaultBackgroundColour : '#064343'
  },
  methods: {
    updateStar(index){
      const starRemarksMapping = {
        1: '"Will not recommend to my friends"',
        2: '"May not recommend to my friends"',
        3: '"It\'s okay"',
        4: '"Likely recommend to my friends!"',
        5: '"Will recommend to my friends!"',
      }
      this.star = index + 1
      this.starRemarks = starRemarksMapping[index]
    },
    goToStage(){
      this.$router.push({ name: 'Stage' })
    },
    leaveGame(){
      if (this.adventureTeam.redirectUrl) {
        let url = this.adventureTeam.redirectUrl;
        if (this.adventureTeam.uiMods.sendTeamInfoOnRedirect) {
          const params = new URLSearchParams({
            teamName: this.adventureTeam.teamName,
            teamCode: this.adventureTeam.teamCode,
            gameCode: this.adventureTeam.sessionId
          });
          url += (url.includes('?') ? '&' : '?') + params.toString();
        }
        window.location = url;
      } else {
        // this.$store.commit('updateUserName', '')
        // this.$store.commit('updateTeamCode', '')
        // this.$store.commit('updateAdventureName', '')
        this.$router.push({ name: 'Start' })
      }
    },
    giveStar(){
      if(this.star){
        this.loading = true
        const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
        masterFunction({
          methodName: 'add-star',
          teamCode: this.$store.state.teamCode,
          userName: this.$store.state.userName,
          star: this.star,
        }).then(res => {
          console.log(res)
          this.loading = false
        }).catch(err => {
          console.log(err)
          this.loading = false
        })
      }
    }
  }
}
</script>

<style scoped>
hr.solid {
  border-top: 3px solid #000;
}
</style>
